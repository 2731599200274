import React from "react";
import starIcon from "../../assets/images/star-icon.png";
import contact from "../../assets/images/contact.png";

// import { PageRenderer } from "gatsby";
import axios from "axios";
import { useState } from "react";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    userName: "",
    userEmail: "",
    subject: "",
    phone_number: "",
    message: "",
  });

  const [statusResponse, setStatusResponse] = useState(null);
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // function submitEmail(e) {
  //   e.preventDefault();
  //   axios.post("http://localhost:3000/contact", formData).then((response) => {
  //     if (response.data.status === "success") {
  //       setStatusResponse("Message Sent");
  //       alert("Message Sent.");
  //     } else if (response.data.status === "fail") {
  //       setStatusResponse("Sending failed");
  //     }
  //   });
  // }
  function resetFornData() {
    formData.userName = "";
    formData.userEmail = "";
    formData.subject = "";
    formData.phone_number = "";
    formData.message = "";
  }

  function submitEmail(e) {
    e.preventDefault();
    axios({
      url: "https://formspree.io/f/mgerebag",
      method: "post",
      headers: {
        Accept: "application/json",
      },
      data: formData,
    }).then((response) => {
      if (response.data.ok === true) {
        resetFornData();
        setStatusResponse("Message Sent");
      } else {
        setStatusResponse("Sending failed");
      }
    });
  }

  React.useEffect(() => {
    if (!statusResponse) return;
    const timeout = setTimeout(() => setStatusResponse(null), 5000);
    return () => clearTimeout(timeout);
  }, [statusResponse]);

  return (
    <section className="contact-area pb-100">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="contact" />
            Get in Touch
          </span>
          <h2>Ready to Get Started?</h2>
          <p>
            Your email address will not be published. Required fields are marked
            *
          </p>
        </div>

        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="contact-image">
              <img src={contact} alt="contact" />
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="contact-form">
              <form id="contactForm" onSubmit={submitEmail}>
                <div className="row">
                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="userName"
                        className="form-control"
                        required
                        placeholder="Your name"
                        value={formData.userName}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <input
                        type="email"
                        name="userEmail"
                        className="form-control"
                        required
                        placeholder="Your email address"
                        value={formData.userEmail}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone_number"
                        className="form-control"
                        required
                        placeholder="Your phone number"
                        value={formData.phone_number}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        name="subject"
                        className="form-control"
                        required
                        placeholder="Subject"
                        value={formData.subject}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        className="form-control"
                        cols="30"
                        rows="6"
                        required
                        placeholder="Write your message..."
                        value={formData.message}
                        onChange={handleInputChange}
                      />
                    </div>
                    {statusResponse && <div>{statusResponse}</div>}{" "}
                  </div>
                  <div className="col-lg-12 col-md-12">
                    <button type="submit" className="default-btn">
                      <i className="flaticon-tick"></i>
                      Send Message <span></span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactForm;
